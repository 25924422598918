export enum CreditCardNetwork {
  Amex = 'amex',
  Dankort = 'dankort',
  Diners = 'diners',
  DinersClub = 'dinersclub',
  Discover = 'discover',
  Forbrugsforeningen = 'forbrugsforeningen',
  Isracard = 'isracard',
  JCB = 'jcb',
  Maestro = 'maestro',
  MasterCard = 'mastercard',
  RuPay = 'rupay',
  Troy = 'troy',
  UnionPay = 'unionpay',
  Visa = 'visa',
  VisaElectron = 'visaelectron',
  Elo = 'elo',
  Hipercard = 'hipercard',
}

export const PAGE_SIZE = 50;
